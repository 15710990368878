import request from '@/router/axios';
import {businessUrl} from "@/config/env"


//查询车间
export const getWorkShop = () => {
    return request({
        url: businessUrl + "department/selectWorkShopName?type=1",
        method: "get",
        params: {
            type: 1
        }
    })
}


//查询工序
export const getProcess = (departmentId) => {
    return request({
        url: businessUrl + "process/selectByWorkShopId",
        method: "get",
        params: {
            departmentId
        }
    })
}

//查询设备
export const getMachine = (workShopId, processId, occurTime) => {
    return request({
        url: businessUrl + "machineIotResetRecord/selectStatusByWorkShopIdAndProcessId",
        method: "post",
        data: {
            workShopId,
            processId,
            occurTime
        }
    })
}

//获取数据模板
export const getTemplateMachineDate = (workShopId, processId, occurTime) => {
    return request({
        url: businessUrl + "machineIotResetRecord/templateRecordResetMachineIotLengthList",
        method: "post",
        data: {
            workShopId,
            processId,
            occurTime
        }
    })
}


//提交数据
export const commitMachineDate = (row) => {
    return request({
        url: businessUrl + "machineIotResetRecord/recordResetMachineIotLengthList",
        method: "post",
        data:
            row
    })
}







