<template>
  <div class="successionManagement" style="height: calc(100% - 320px);">
    <div class="top-heaer">
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark d-flex">
            <div class="d-flex">
              <div class="lable">
                <p>车间选择</p>
              </div>
              <div>
                <el-select v-model="workShopId" @change="getWorkShopId" placeholder="请选择车间">
                  <el-option
                      v-for="item in workShopList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="d-flex" style="margin-left: 50px;">
              <div class="lable">
                <p>工序选择</p>
              </div>
              <div>
                <el-select v-model="processId" placeholder="请选择工序">
                  <el-option
                      v-for="item in processList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="d-flex" style="margin-left: 50px;">
              <div class="lable">
                <p>日期选择</p>
              </div>
              <div>
                <el-date-picker v-model="occurTime" @change="getMachineId"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="datetime"
                                :clearable="false"
                                placeholder="选择日期" style="width:100%;">
                </el-date-picker>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <div class="select-box d-flex">
        <div class="btn-box">
          <el-button type="primary" class="btn" :class="isIndex == 0 ? 'btn-ac' : ''" @click="standAlone">
            单机模式
          </el-button>
        </div>
        <div class="btn-box">
          <el-button type="primary" class="btn" :class="isIndex == 1 ? 'btn-ac' : ''" @click="batch">
            批量模式
          </el-button>
        </div>
      </div>
    </div>
    <div class="combined-query d-flex" style="justify-content: space-between">
      <div class="grid-content bg-purple-dark d-flex">
        <div class="d-flex">
          <div class="lable">
            <p>设备选择</p>
          </div>
          <div>
            <el-input
                @click.native="dialogVisible = true"
                :disabled="true"
                placeholder="请点击选择设备"
                v-model="machineInfo.code"
            >
            </el-input>
          </div>
          <!--<div style="margin-left: 10px;">-->
          <!--<el-button type="primary" class="bg7caff5 border-none">上一个</el-button>-->
          <!--<el-button type="primary" class="bg7caff5 border-none">下一个</el-button>-->
          <!--</div>-->
        </div>
        <div class="d-flex" style="margin-left: 50px;">
          <div class="lable">
            <p>清零前设备米数</p>
          </div>
          <div>
            <el-input
                type="number"
                placeholder="请输入设备米数"
                v-model="beforeValue"
                clearable>
            </el-input>
          </div>
        </div>
        <div class="d-flex" style="margin-left: 50px;">
          <div class="lable">
            <p>清零后设备米数</p>
          </div>
          <div>
            <el-input
                type="number"
                placeholder="请输入设备米数"
                v-model="aftetValue"
                clearable>
            </el-input>
          </div>
        </div>
      </div>
      <div>
        <el-button type="primary" @click="addMachineDate" class="bg7caff5 border-none">添加</el-button>
      </div>
    </div>
    <el-dialog
        title="设备选择"
        :visible.sync="dialogVisible"
        width="50%"
    >
      <el-button style="width:120px;margin-left: 10px;margin-bottom: 15px" v-for="item in machineList"
                 :key="item.id" @click="getOneMachine(item)" :type="item.useStatus == '1'?'success':'info'" plain>
        {{ item.code1 }}
      </el-button>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
  </span>
    </el-dialog>
    <div class="table" style="height: 100%;">
      <el-table
          :data="list"
          border
          height="100%"
          ref="table"
          style="width: 100%">
        <el-table-column
            prop="occurTime"
            label="日期"
            align="center"
            width="">
        </el-table-column>
        <el-table-column
            prop="workShopName"
            label="车间"
            align="center"
            width="">
        </el-table-column>
        <el-table-column
            prop="machineCode"
            label="设备"
            align="center"
            width="">
        </el-table-column>
        <el-table-column
            prop="beforeIotLength"
            label="清零前IOT米数"
            align="center"
            width="">
          <template slot-scope="scope">
            <el-input type="number" v-model="scope.row.beforeIotLength"/>
          </template>
        </el-table-column>
        <el-table-column
            prop="afterIotLength"
            label="清零后IOT米数"
            align="center"
            width="">
          <template slot-scope="scope">
            <el-input type="number" v-model="scope.row.afterIotLength"/>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            align="center"
            width="100">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="deleteRow(scope.$index, list)"
                type="text"
                size="small">
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-button type="primary" style="float: right;margin-top: 20px" @click="subMachineDate"
               class="bg7caff5 border-none">提交
    </el-button>
  </div>
</template>

<script>
import {
  getWorkShop,
  getProcess,
  getMachine,
  commitMachineDate,
  getTemplateMachineDate
} from '@/api/resetLengthManagement'

export default {
  name: 'successionManagement',
  data () {
    return {
      isIndex: 0,
      value: '',
      input: '',
      options: [],
      tableData: [],
      workShopList: [],
      processList: [],
      templateList: [],
      machineId: '',
      workShopId: '',
      processId: '',
      machineList: [],
      dialogVisible: false,
      machineInput: '',
      occurTime: '',
      machineInfo: '',
      beforeValue: '',
      aftetValue: '',
      list: [],
      workShopName: '',

    }
  },
  created () {
    getWorkShop().then(res => {
      console.log(res, '9999999999')
      var date = new Date()
      var year = date.getFullYear() //月份从0~11，所以加一
      var dateArr = [date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()]
      for (var i = 0; i < dateArr.length; i++) {
        if (dateArr[i] >= 1 && dateArr[i] <= 9) {
          dateArr[i] = '0' + dateArr[i]
        }
      }
      this.occurTime = year + '-' + dateArr[0] + '-' + dateArr[1] + ' ' + dateArr[2] + ':' + dateArr[3] + ':' + dateArr[4]
      this.workShopList = res.data.data
      this.workShopId = this.workShopList[0].id
      this.workShopName = this.workShopList[0].name
      getProcess(this.workShopId).then(res => {
        this.processList = res.data.data
        this.processId = this.processList[0].id
        getMachine(this.workShopId, this.processId, this.occurTime).then(res => {
          this.machineList = res.data.data
          this.machineList.forEach((f) => {
            f.code1 = f.code
          })
        })
      })

    })

  },
  methods: {
    getWorkShopId (val) {
      let mList = this.workShopList.find(item => item.id == val)
      this.workShopName = mList.name
      getProcess(val).then(res => {
        console.log(res, '9999999999')
        this.processList = res.data.data
        this.machineList.forEach((f) => {
          f.code1 = f.code
        })
      })
    },
    getMachineId (val) {
      getMachine(this.workShopId, this.processId, val).then(res => {
        console.log(res, '888888')
        this.machineList = res.data.data
      })
    },
    getOneMachine (i) {
      console.log('777777777', i)
      this.machineInfo = i
      this.dialogVisible = false
    },
    addMachineDate () {
      let obj = {
        'occurTime': this.occurTime,
        'workShopName': this.workShopName,
        'workShopId': this.workShopId,
        'machineCode': this.machineInfo.code,
        'machineId': this.machineInfo.id,
        'beforeIotLength': this.beforeValue,
        'afterIotLength': this.aftetValue
      }
      this.list.push(obj)
      console.log(this.list)
    },
    subMachineDate () {
      // machineList
      commitMachineDate(this.list).then(res => {
        this.list = res.data.data
        if (res.data) {
          this.machineInfo.code = ''
          this.beforeValue = ''
          this.aftetValue = ''
          if (res.data.data.length > 0) {
            this.$message({
              message: '提交成功,' + '有' + res.data.data.length + '数据有误，请查证！',
              type: 'success'
            })
          } else {
            this.$message({
              message: '提交成功',
              type: 'success'
            })
          }
        }
      })
    },
    deleteRow (index, rows) {
      rows.splice(index, 1)
    },
    batch () {
      this.isIndex = 1
      getTemplateMachineDate(this.workShopId, this.processId, this.occurTime).then(res => {
        console.log(res, '666666')
        let template = this.templateList = res.data.data
        console.log(template, '++++++++++++++')
        for (let templateKey in template) {
          console.log(templateKey, '5555555')
          let obj1 = {
            'occurTime': template[templateKey].occurTime,
            'workShopName': template[templateKey].workShopName,
            'workShopId': template[templateKey].workShopId,
            'machineCode': template[templateKey].machineCode,
            'machineId': template[templateKey].machineId,
            'beforeIotLength': template[templateKey].beforeIotLength,
            'afterIotLength': template[templateKey].afterIotLength
          }
          this.list.push(obj1)
        }
        console.log(this.list, '444444444')
      })
    },
    standAlone () {
      this.isIndex = 0
      this.list = []
    }

  },
  watch:{
    machineList(New) {
      console.log(New)
    }
  }
}
</script>

<style scoped lang="scss">
.successionManagement {
  padding: 20px;
  background: rgba(245, 246, 248, 1);

  .border-none {
    border: none;
  }

  .bg7caff5 {
    background: #7CAFF5;
  }

  .top-heaer {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
  }

  .lable {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .d-flex {
    display: flex;
  }

  .select-box {
    padding-left: 30px;
    height: 70px;
    border-bottom: 1px solid rgba(151, 151, 151, 1);
    margin-bottom: 30px;

    .btn-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 30px;

      .btn {
        background: none;
        color: rgba(0, 0, 0, .6);
        border: none;
      }

      .btn-ac {
        background: rgba(90, 155, 248, 1);
        color: #fff;
      }
    }
  }

  .table {
    margin-top: 35px;

    /deep/ th {
      background: rgba(224, 226, 228, 1) !important;
      color: #000 !important;
    }
  }
}
</style>
